<template>
  <h-card class="skeleton-message card-hover mb-3 shadow-sm">
    <div class="d-none d-xxl-block">
      <vue-content-loading :width="1130" :height="45">
        <rect x="5" y="6" rx="1" ry="1" width="280" height="25" />
        <rect x="580" y="6" rx="4" ry="4" width="240" height="25" />
        <rect x="1040" y="6" rx="4" ry="4" width="90" height="25" />
      </vue-content-loading>
    </div>
    <div class="d-none d-xl-block">
      <vue-content-loading :width="1070" :height="45">
        <rect x="2" y="6" rx="1" ry="1" width="280" height="25" />
        <rect x="550" y="6" rx="4" ry="4" width="220" height="25" />
        <rect x="980" y="6" rx="4" ry="4" width="80" height="25" />
      </vue-content-loading>
    </div>
    <div class="d-none d-lg-block">
      <vue-content-loading :width="900" :height="45">
        <rect x="2" y="6" rx="1" ry="1" width="260" height="25" />
        <rect x="460" y="6" rx="4" ry="4" width="200" height="25" />
        <rect x="800" y="6" rx="4" ry="4" width="80" height="25" />
      </vue-content-loading>
    </div>
    <div class="d-none d-md-block">
      <vue-content-loading :width="690" :height="45">
        <rect x="0" y="6" rx="1" ry="1" width="200" height="25" />
        <rect x="335" y="6" rx="4" ry="4" width="180" height="25" />
        <rect x="560" y="6" rx="4" ry="4" width="80" height="25" />
      </vue-content-loading>
    </div>
    <div
      class="d-flex d-md-none d-lg-none d-xl-none d-xxl-none align-items-center justify-content-center"
    >
      <b-spinner class="grey mt-4"></b-spinner>
    </div>
  </h-card>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  components: {
    VueContentLoading,
  },
}
</script>

<style lang="scss" scoped>
svg {
  width: 100%;
  padding-bottom: 92%;
  height: 1px;
  overflow: visible;
}
.skeleton-message {
  height: 121px;
}
.grey {
  color: #e1e1e1;
}
@media screen and (min-width: 768px) {
  .skeleton-message {
    height: 78px;
  }
}
</style>
