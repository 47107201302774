<template>
  <div>
    <h-card class="card-hover mb-3 shadow-sm">
      <b-row class="align-items-center">
        <b-col cols="5" md="6">
          <b>{{ schedule.name }}</b>
        </b-col>
        <b-col cols="5" md="4" v-if="schedule.endDate">
          <span v-if="!isPermanent">Du</span>
          <span v-else>A partir du</span>
          {{ schedule.startDate | moment('DD/MM/YYYY') }}
          <span v-if="!isPermanent"
            >Au {{ schedule.endDate | moment('DD/MM/YYYY') }}</span
          >
        </b-col>
        <b-col cols="2" class="icons d-flex justify-content-end">
          <b-button
            @click="editSchedule(schedule.idSchedule)"
            variant="outline-light"
            ><b-icon variant="info" icon="pencil-square"></b-icon
          ></b-button>
          <b-button @click="showModal" variant="outline-light" class="ml-2"
            ><b-icon variant="danger" icon="trash-fill"></b-icon
          ></b-button>
        </b-col>
      </b-row>
      <b-modal ref="confirm-delete-modal" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            Êtes-vous sûr de vouloir supprimer définitivement la planification
            <b>{{ schedule.name }}</b> ? <br />Le message ne sera plus diffusé.
          </h5>
        </div>
        <div class="d-flex mt-3 ">
          <h-button @click="hideModal" outline grey class="ml-auto px-3 mr-3">
            {{ $t('actions.no') }}
          </h-button>
          <h-button class="px-3" red @click="deleteItem(schedule)">
            {{ $t('actions.yes') }}
          </h-button>
        </div>
      </b-modal>
    </h-card>
  </div>
</template>

<script>
import messageService from '@/services/messageService.js'
export default {
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editSchedule(id) {
      this.$router.push({ name: 'schedule', params: { id: id } })
    },
    async deleteItem(schedule) {
      try {
        const res = await messageService.deleteSchedule(schedule.idSchedule)
        if (res && res.data) {
          this.$store.dispatch('schedule/_getSchedules')
          this.$refs['confirm-delete-modal'].hide()
          this.$toast.success(
            `La planification ${schedule.name} a été supprimée`
          )
        }
      } catch (e) {
        this.$refs['confirm-delete-modal'].hide()
        this.$toast.error("Une erreur s'est produite. Veuillez réessayer.")
      }
    },
    showModal() {
      this.$refs['confirm-delete-modal'].show()
    },
    hideModal() {
      this.$refs['confirm-delete-modal'].hide()
    },
  },
  computed: {
    isPermanent() {
      const endDate = this.$moment(this.schedule.endDate).format('YYYY')
      if (endDate === '2099' || endDate === '2060') return true
      else return false
    },
  },
}
</script>

<style lang="scss" scoped>
.card-hover:hover {
  -webkit-box-shadow: 0 0.25rem 0.4rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}
h5 {
  line-height: 35px;
}
@media screen and (max-width: 767.5px) {
  .icons {
    padding: 0;
    flex-direction: column;
    align-items: center;
    button {
      padding: 4px 6px;
    }
    button:last-child {
      margin-top: 3px;
      margin-left: 0 !important;
    }
  }
}
</style>
