<template>
  <b-container>
    <HPageTitle>
      Messages
      <template v-slot:action>
        <router-link :to="{ name: 'recorder' }">
          <h-button blue id="RecordButton">
            <b-icon icon="plus-circle-fill" class="w-15px mr-2" font-scale="1">
            </b-icon>
            {{ $t('messages.recordMessage') }}
          </h-button>
        </router-link>
      </template>
    </HPageTitle>
    <b-row class="mb-4">
      <b-col cols="12">
        <template v-if="schedulesLoaded && schedules.length > 0">
          <transition-group name="list" tag="p">
            <SchedulesItem
              v-for="schedule in schedules"
              :schedule="schedule"
              :key="schedule.idSchedule"
            />
          </transition-group>
        </template>
        <template v-else-if="schedulesLoaded && schedules.length === 0">
          <h-card class="w-100">
            <b-row class="justify-content-center">
              <b-col
                cols="12"
                lg="5"
                md="8"
                sm="10"
                class="d-flex flex-column align-items-center"
              >
                <b-img
                  :src="require('@/assets/svg/no_data_schedule.svg')"
                  alt="radioshop logo"
                  class="w-75 my-4"
                ></b-img>
                <h5 class="mt-4 text-center">
                  {{ $t('messages.emptyMessage') }}
                </h5>
              </b-col>
            </b-row>
          </h-card>
        </template>
        <template v-else>
          <ScheduleItemSkeleton v-for="i in 6" :key="i" />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SchedulesItem from '@/components/SchedulesItem.vue'
import ScheduleItemSkeleton from '@/components/skeleton/ScheduleItemSkeleton.vue'
import { mapState } from 'vuex'
export default {
  components: {
    SchedulesItem,
    ScheduleItemSkeleton,
  },
  created() {
    this.$store.dispatch('schedule/_getSchedules')
  },
  computed: {
    ...mapState({
      schedules: (state) => state.schedule.schedules,
      schedulesLoaded: (state) => state.schedule.schedulesLoaded,
    }),
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.4s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
